import React from "react";
import { View, Platform, GestureResponderEvent, Linking } from "react-native";
import { useTrack, track, capture } from "shared/utils/tracking";

import { Colors, Dynamic, Spacing } from "shared/utils/theme";
import { reloadApp } from "shared/utils/platform";

import { Text } from "shared/components/Text";
import { Button } from "shared/components/Button";
import { LottieView } from "shared/components/LottieView";
import { getUserID } from "shared/utils/platform";
import Constants from "expo-constants";
import { useNotification } from "shared/components/NotificationOverlay";

interface ErrorProps {
  error?: string;
  retry?: (event?: GestureResponderEvent) => void;
}

const ErrorView: React.FC<ErrorProps> = ({
  error = "Irgendwas ist schief gelaufen, aber was, wissen wir ehrlichgesagt auch nicht.",
  retry = undefined,
}) => {
  const showNotification = useNotification();
  const trackEvent = useTrack();

  return (
    <View
      style={{
        flex: 1,
        padding: Spacing.between,
        maxWidth: Dynamic.width,
        marginTop: Spacing.between * 4,
        alignItems: "stretch",
      }}
    >
      <LottieView
        style={{
          width: 144,
          height: 144,
          alignSelf: "center",
          marginVertical: Spacing.between,
        }}
        autoPlay
        loop={true}
        speed={0.3}
        source={require("shared/assets/animations/error-action.json")}
      />
      <Text style={{ paddingVertical: Spacing.betweenHalf }} type={"body"}>
        {error}
      </Text>
      {retry && (
        <Button
          outline={true}
          invert={true}
          children={"Nochmal versuchen"}
          onPress={retry}
          style={{ paddingVertical: Spacing.betweenHalf }}
        />
      )}
      <Button
        outline={true}
        foreground={Colors.primary}
        background={Colors.background}
        children={"Schreib uns eine E-Mail"}
        onPress={() =>
          Linking.openURL(
            `mailto:karl@kulta.app?subject=KULTA%20Crash&body=${encodeURIComponent(
              "Wenn du diese E-Mail abschickst, können wir deinen anonymisierten Fehlerbericht zuordnen. Du kannst uns gern noch mehr über das Problem schreiben. \n\n ID zur Zuordnung (nicht löschen): " +
                getUserID()
            )}`
          )
        }
        style={{ paddingVertical: Spacing.betweenHalf }}
      />
      <Button
        outline={true}
        foreground={Colors.warning}
        background={Colors.background}
        children={"App neu starten"}
        onPress={() => {
          showNotification({
            text: "Lädt neu...",
            lottie: {
              speed: 2,
              loop: true,
              source: require("shared/assets/animations/broken-robot.json"),
            },
          });
          trackEvent("Reload App", { actionType: "reloadApp" });
          reloadApp();
        }}
        style={{ paddingVertical: Spacing.betweenHalf }}
      />
    </View>
  );
};

type ErrorBoundaryProps = {
  children?: any;
  fallback?: any;
  onError?: (error: Error, componentStack: string) => void;
};

type ErrorInfo = {
  componentStack: string;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props;

    if (typeof onError === "function") {
      try {
        onError.call(this, error, info ? info.componentStack : "");
      } catch (ignoredError) {}
    }
    track("ErrorBoundary Caught", {
      actionType: "error",
      resourceName: error.name,
      message: error.message,
      extraName: "errorData",
      extraValue: JSON.stringify({ error, info }),
    });
    capture(error, {
      extra: info,
      tags: { trackingMethod: "ErrorBoundary", invisible: "false" },
    });
    this.setState({ error, info });
  }

  render() {
    const { error, info } = this.state;

    if (error !== null && this.props?.fallback != null) {
      return this.props.fallback;
    } else if (error !== null) {
      return (
        <ErrorView
          error={`Da ist irgendwas schiefgelaufen. Ein anonymisierter Fehlerbericht wurde an das KULTA Team übermittelt. \n \nFehler: ${String(
            error
          )}`}
        />
      );
    }

    return this.props?.children || null;
  }
}

class HideErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props;

    if (typeof onError === "function") {
      try {
        onError.call(this, error, info ? info.componentStack : "");
      } catch (ignoredError) {}
    }
    track("NullErrorBoundary Caught", {
      actionType: "error",
      resourceName: error.name,
      message: error.message,
      extraName: "errorData",
      extraValue: JSON.stringify({ error, info }),
    });
    capture(error, {
      extra: info,
      tags: { trackingMethod: "HideErrorBoundary", invisible: "true" },
    });
    this.setState({ error, info });
  }

  render() {
    const { children } = this.props;
    const { error, info } = this.state;

    if (error !== null) {
      return null;
    }

    return children || null;
  }
}

function getDebug() {
  const version = `Version ${Constants.expoConfig?.version} ${Constants.expoConfig?.description}`;

  const debugInfo = `
    description:
    
    userID: ${getUserID()}
    version: ${version}
    platform: ${Platform.OS} ${Platform.Version}
  `;
  const debugMailto = `mailto:karl@kulta.app?subject=debug&body=${encodeURIComponent(
    debugInfo
  )}`;
  return { debugInfo, debugMailto, version };
}

function sendDebug() {
  const { debugMailto } = getDebug();
  Linking.openURL(debugMailto);
}

export { ErrorView, ErrorBoundary, getDebug, HideErrorBoundary, sendDebug };
