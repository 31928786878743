import React, { useCallback } from "react";
import { View, ViewProps, Linking, Platform } from "react-native";
import Hyperlink from "react-native-hyperlink";
import { Text, TextStyleType } from "shared/components/Text";
import { Colors } from "shared/utils/theme";
import { useTrack } from "shared/utils/tracking";

interface BodyProps extends ViewProps {
  textType?: TextStyleType;
}

const Body: React.FC<BodyProps> = ({
  children,
  textType = "body",
  ...rest
}) => {
  const track = useTrack();
  const onLinkPress = useCallback(
    async (url) => {
      await Linking.openURL(url);
      track("Tapped Link in Text", {
        actionType: "tapLink",
        resourceType: "url",
        resourceId: url,
      });
    },
    [track]
  );
  return (
    <View {...rest}>
      <Hyperlink
        onPress={onLinkPress}
        linkStyle={{ color: Colors.link, fontWeight: "bold" }}
        linkDefault={true}
      >
        <Text type={textType} allowFontScaling={false}>
          {typeof children === "string" ? children.trim() : children}
        </Text>
      </Hyperlink>
    </View>
  );
};

export { Body };
