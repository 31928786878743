import { useRef, useState, useEffect, useCallback } from "react";
import { ScrollView, AccessibilityInfo } from "react-native";
import { Dynamic } from "shared/utils/theme";

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    } else {
      return null;
    }
  }, [delay]);
}

function useLazyAssign(expensive, cheap) {
  const [value, setValue] = useState(cheap);
  useEffect(() => {
    setTimeout(() => setValue(expensive), 10);
  }, [expensive]);
  return value;
}

function useTimeout(callback: () => void, delay: number) {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(tick, delay);
      return () => clearInterval(id);
    } else {
      return null;
    }
  }, [delay]);
}

function useScreenReaderEnabled() {
  const [screenReaderEnabled, setScreenReaderEnabled] = useState(false);

  useEffect(() => {
    const name = "screenReaderChanged";
    const handler = (screenReaderEnabled) => {
      setScreenReaderEnabled(screenReaderEnabled);
    };
    AccessibilityInfo.addEventListener(name, handler);

    AccessibilityInfo.isScreenReaderEnabled().then((screenReaderEnabled) => {
      setScreenReaderEnabled(screenReaderEnabled);
    });

    return () => {
      AccessibilityInfo.removeEventListener(name, handler);
    };
  }, []);

  return screenReaderEnabled;
}

function useSimplePagination({ limit = 10, initial = 0, threshold = 1 }) {
  const [offset, setOffset] = useState(initial);
  const onEndReached = useCallback(() => {
    setOffset((o) => o + limit);
  }, [limit]);
  const vars = { offset, limit };
  const listeners = { onEndReached, onEndReachedThreshold: threshold };
  return { listeners, vars };
}

function useScrollToID({
  ref = undefined,
  id: activeID,
  numberOfItems,
  horizontal = true,
}) {
  const [scrollToPoints, setScrollToPoints] = useState({});
  const scrollPointsRef = useRef({});
  const internalRef = useRef<ScrollView>(null);
  const refToUse = ref || internalRef;
  useEffect(() => {
    if (Object.keys(scrollToPoints).length > 0) {
      refToUse.current.scrollTo({
        x: horizontal
          ? scrollToPoints[String(activeID)] - Dynamic.width / 2
          : 0,
        y: horizontal
          ? 0
          : scrollToPoints[String(activeID)] - Dynamic.height / 2,
      });
    }
  }, [horizontal, activeID, refToUse, scrollToPoints]);
  const makeOnLayout = useCallback(
    (id) => {
      return ({ nativeEvent: { layout } }) => {
        scrollPointsRef.current[String(id)] =
          (horizontal ? layout.x : layout.y) +
          (horizontal ? layout.width : layout.height) / 2;
        if (Object.keys(scrollPointsRef.current).length === numberOfItems) {
          setScrollToPoints({ ...scrollPointsRef.current });
        }
      };
    },
    [horizontal, numberOfItems]
  );
  return { scrollRef: refToUse, makeOnLayout };
}

export {
  useLazyAssign,
  useTimeout,
  useInterval,
  useScrollToID,
  useScreenReaderEnabled,
  useSimplePagination,
};
