import React from "react";
import { Spacing, Colors, Radius, Dynamic } from "shared/utils/theme";

interface Props {
  hideAfter?: number;
  icon?: string;
  lottie?: object;
  haptic?: string;
  text: string;
  componentId: string;
}

interface NotificationOverlayState {
  expired: boolean;
}

const NotificationOverlay: React.FC<Props> = React.memo(
  ({ hideAfter = 1000, icon, text, lottie, componentId, haptic }) => {
    return null;
  }
);

function showNotification({
  text = "",
  icon = "info",
  lottie = undefined,
  haptic = undefined,
  hideAfter = 2000,
  color = Colors.generic.white,
}) {}

export { NotificationOverlay, showNotification };
