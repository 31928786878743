async function reloadApp() {}

async function clearCache() {}

function blockThread(ms) {
  var date = new Date();
  var curDate: Date = null;
  do {
    curDate = new Date();
  } while (curDate.getTime() - date.getTime() < ms);
}

function getUserID() {
  return "";
}

function hapticFeedback(name: any, config: any) {
  console.info("Haptic Feedback is a noop");
}

function sleep() {}

export { hapticFeedback, reloadApp, clearCache, blockThread, getUserID, sleep };
